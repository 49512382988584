<!--
 * @Description: 车行道停车运营管理 路段管理 路段配置 onroadRoadParkSpace
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-19 14:10:55
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="路段名称">
              <el-select v-model="searchForm.parkId"
                         filterable
                         placeholder="全部">
                <el-option v-for="item in allParkingList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场类型">
              <el-select v-model="searchForm.isEnabled"
                         filterable
                         placeholder="全部">
                <el-option v-for="item in operateList"
                           :key="item.isEnabled"
                           :label="item.name"
                           :value="item.isEnabled">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button style="margin-left:17px;"
                     type="info"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="12">路段泊位信息管理</el-col>
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     v-show="insert_user"
                     @click="insertUser">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     v-show="import_dialog"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList"
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="是否运营">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.isEnabled"
                           inactive-color="#ff4949"
                           active-color="#13ce66"
                           :inactive-value="'0'"
                           :active-value="'1'"
                           :disabled = "isDisabled"
                           @change="changeSwitch(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="区域配置">
              <template slot-scope="scope">
                <el-button @click="handleClickLineItem(scope.row)"
                           type="text"
                           size="medium">区域管理</el-button>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClickUpdate(scope.row)"
                             v-show="click_update"
                             type="text"
                             size="medium">修 改</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             v-show="click_delete"
                             @click="handleClickDelete(scope.row)">删 除</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             v-show="update_yuwei"
                             @click="handleClickUpdateYuwei(scope.row)">余位修改</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 弹出层 -->
    <el-dialog :title="this.dialogTitle"
               append-to-body
               @close="popClose"
               :visible.sync="dialogVisible">
      <el-form :model="instreForm"
               :label-position="labelPosition"
               :rules="rules"
               ref="instreForm"
               size="small"
               label-width="110px">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="停车路段编号"
                          prop="parkId">
              <el-input v-model="instreForm.parkId"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"
                        :disabled="redact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路段名称"
                          prop="parkName">
              <el-input v-model="instreForm.parkName"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"
                        :disabled="redact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属企业"
                          prop="companyId">
              <el-select class="width"
                         style="width:63%"
                         v-model="instreForm.companyId"
                         placeholder="请选择">
                <el-option v-for="(item, index) in enterpriseList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计费规则"
                          prop="billingRuleDefId">
              <el-select class="width"
                         style="width:63%"
                         v-model="instreForm.billingRuleDefId"
                         placeholder="请选择">
                <el-option v-for="(item, index) in billingRulesList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="行政区域"
                          prop="areaCode">
              <el-select v-model="instreForm.areaCode"
                         style="width:63%"
                         placeholder="请选择"
                         @change="handleChangeAreaCode">
                <el-option v-for="(item, index) in regionCodeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属路段"
                          prop="roadCode">
              <el-select v-model="instreForm.roadCode"
                         style="width:63%"
                         placeholder="请选择">
                <el-option v-for="(item, index) in roadList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="地址"
                          prop="address">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.address"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规划车位数"
                          prop="parkSpaceNum">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.parkSpaceNum"
                        oninput="value=value.replace(/[^\d]/g,'');"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12"></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class='mapContent'>
                <el-button type="success"
                           size="medium"
                           @click="mapClick">地图</el-button>
                <el-row class="map-container"
                        v-if="dialogVisibleMap">
                  <div id="map"></div>
                </el-row>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input v-model="instreForm.longitude"
                        class="width"
                        type="text"
                        style="width:63%"
                        placeholder="请点击地图获取"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input v-model="instreForm.latitude"
                        class="width"
                        type="text"
                        style="width:63%"
                        placeholder="请点击地图获取"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话"
                          prop="contactPhoneNumber">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.contactPhoneNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="handleSave('instreForm')">确 定</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary">
            <a :href="templateDl"
               class="download"
               download=""
               style="color: #ffffff;text-decoration:none">模板下载</a>
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 余位修改 -->
    <el-dialog title="余位修改"
               append-to-body
               :visible.sync="updateYuweiVisible">
      <el-form :model="updateYuweiForm"
               :rules="rules"
               ref="updateYuweiForm"
               size="small"
               label-width="110px">
        <el-row style="color:white;margin:0 0 20px 55px;">
          当前余位:{{ currentEmptySpace }}
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="余位数"
                          prop="emptySpace">
              <el-input v-model="updateYuweiForm.emptySpace"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="updateYuwei('updateYuweiForm')">确 定</el-button>
            <el-button class="Fbutton"
                       type="info"
                       @click="updateYuweiVisible = false">取 消</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      isDisabled:true,
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {},
      pageNum: 1,
      pageSize: 15,
      tableFun: { 'selection-change': this.handleChange, },
      tableColumnList: [
        {
          prop: 'parkId',
          label: '路段编号',
        },
        {
          prop: 'parkName',
          label: '路段名称',
        },
        {
          prop: 'contact',
          label: '联系人',
        },
        {
          prop: 'contactPhoneNumber',
          label: '联系电话',
        },
      ],
      multiSelectList: [], // 多选列表
      instreForm: {}, // 新增表单
      parkTypeList: [],//停车场类型列表
      billingRulesList: [],// 计费规则列表
      enterpriseList: [],  //归属企业例表
      labelPosition: 'left', //对齐方向
      dialogVisible: false,// 弹出框
      dialogVisibleMap: false,//地图容器显示
      dialogTitle: '', //弹出框title
      redact: false, //编辑
      allParkingList: [],//停车场下拉列表

      operateList: [
        { name: '运营路段', isEnabled: "1" },
        { name: '非运营路段', isEnabled: "0" },
      ],// 运营非运营停车场类型 1运营 0非运营

      rules: {
        companyId: [{ required: true, message: '请选择所属企业', trigger: 'change' }],
        billingRuleDefId: [{ required: true, message: '请选择计费规则', trigger: 'change' }],
        areaCode: [{ required: true, message: '请选择所属区域', trigger: 'change' }],
        roadCode: [{ required: true, message: '请选择所属路段', trigger: 'change' }],
        parkName: [{ required: true, message: '请输入停车路段名', trigger: 'blur' }],
        parkId: [{ required: true, message: '请输入停车路段编号', trigger: 'blur' }],
        parkSpaceNum: [{ required: true, message: '请输入车位数辆', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, validator: this.publicFun.verifyTel, trigger: 'blur' }],
        emptySpace: [{ required: true, message: '请输入余位数', trigger: 'blur' }],
      },
      regionCodeList: [],//行政区域-路段级联
      roadList: [],// 路段列表
      map: null, // 地图

      updateYuweiVisible: false,
      updateYuweiForm: {},// 修改余位
      currentEmptySpace: '暂无',// 当前余位

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //菜单按钮权限
    this.buttonList = localStorage.getItem('buttonListByUser')
    this.buttonShow();
    this.templateDl = this.$downloadBaseUrl + "ParkController/downloadTemplate";
    this.queryTableList()
    this.getQueryDictCom()
    this.getAccountingRules()
    this.getRegion()
    this.getAllparkingList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //菜单按钮展示权限
    buttonShow () {
      const buttonMenuName = JSON.parse(this.buttonList)
      const menuNames = [];
      for (let i = 0; i < Object.keys(buttonMenuName).length; i++) {
        const menuName = buttonMenuName[i].menuName;
        menuNames.push(menuName);
        if (menuNames[i] === '新增') {
          this.insert_user = true
        }
        else if (menuNames[i] === '修改') {
          this.click_update = true
        }
        else if (menuNames[i] === '删除') {
          this.click_delete = true
        }
        else if (menuNames[i] === '余位修改') {
          this.update_yuwei = true
        }
        else if (menuNames[i] === '是否运营') {
          this.isDisabled = false
        }
        else if (menuNames[i] === '导入') {
          this.import_dialog = true
        }
      }
    },
    // 是否运营
    changeSwitch (row) {
      if (row.isEnabled === '1') {
        let info = {
          parkId: row.parkId,
          isEnabled: "1"
        }
        this.$yardManagementApi.changeParkStatus(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '设置运营路段成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
      else if (row.isEnabled === '0') {
        let info = {
          parkId: row.parkId,
          isEnabled: "0"
        }
        this.$yardManagementApi.changeParkStatus(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '设置非运营路段成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
    },
    // 点击修改余位
    handleClickUpdateYuwei (row) {
      let info = {
        parkId: row.parkId,
      }
      this.$yardManagementApi.getEmptySpace(info).then(res => {
        if (!res.resultEntity) {
          this.currentEmptySpace = '暂无'
        } else {
          this.currentEmptySpace = res.resultEntity
        }
        if (this.updateYuweiForm.emptySpace) {
          this.updateYuweiForm.emptySpace = ""
        }
        this.updateYuweiForm = {}
        this.updateYuweiForm = row
        this.updateYuweiVisible = true
      })
    },
    // 余位修改确认
    updateYuwei (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let info = {
            parkId: this.updateYuweiForm.parkId,
            emptySpace: this.updateYuweiForm.emptySpace
          }
          this.$yardManagementApi.updateEmptySpace(info).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
            this.queryTableList()
            this.updateYuweiVisible = false
          })
        } else {
          return false;
        }
      });
    },
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "ParkController/uploadIn";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    //获取停车场列表
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.searchForm['parkTypeCode'] = '1';
      this.$yardManagementApi.ParkController(this.searchForm).then(res => {

        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //查询
    onSubmit () {
      this.searchForm.pageNum = 1
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {
        pageNum: 1,
        pageSize: 15
      }
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //弹出框确定按钮
    handleSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.instreForm.parkSpaceNum = Number(this.instreForm.parkSpaceNum)
          let info = {
            parkTypeCode: '1', ...this.instreForm
          }
          if (this.dialogTitle == '新增停车路段') {
            this.$yardManagementApi.newParkController(info).then(() => {
              // 同时添加平层
              let info =
              {
                parkId: this.instreForm.parkId,
                parkLayerName: this.instreForm.parkName,
                parkSpaceNum: this.instreForm.companyId
              }
              this.$yardManagementApi.insertParkLayerController(info).then(res => {
                if (res.resultCode === '2000') {
                  this.$message({ message: '新增时同时添加默认平层成功', type: 'success' });
                  this.queryTableList()
                  this.dialogVisible = false
                }
              })
            }).catch((error) => {
              this.$message.error({ message: error.resultMsg });
            })
            // 修改信息
          } else if (this.dialogTitle == '修改停车路段') {
            this.$yardManagementApi.updateParkController(this.instreForm).then(() => {
              this.$message({ message: '修改成功', type: 'success' });
              this.queryTableList()
              this.dialogVisible = false
            })
          }
        } else {
          return false;
        }
      });
    },
    // 弹出框地图
    mapClick () {
      this.dialogVisibleMap = !this.dialogVisibleMap
      setTimeout(() => {
        this.initMap()
      }, 0);
    },
    //初始化地图
    initMap () {
      let themeType = {
        dark: 'amap://styles/a1ef87b8517f7bb18e73e47a65ac69c9',
        light: 'amap://styles/37bc333a00b6dc86dbd9309cae53802f'
      }
      this.map = new window.AMap.Map('map', {
        mapStyle: themeType[this.theme],
        resizeEnable: true,
        zoom: 17, // 缩放级别
        zooms: [3, 20], // 缩放级别
        center: [106.562865, 29.600376], // 中心点坐标
        viewMode: '3D',//使用3D视图
        pitch: 60,
      })
      //地图点击事件
      let that = this
      this.map.on("click", showInfoClick);
      function showInfoClick (e) {
        let longitude = e.lnglat.getLng(); //获取经度
        let latitude = e.lnglat.getLat(); //获取纬度
        let form = that.$deepClone(that.instreForm)
        form.longitude = longitude
        form.latitude = latitude
        that.instreForm = form
      }
    },
    // 新增
    insertUser () {
      this.dialogTitle = "新增停车路段"
      this.redact = false
      this.dialogVisible = true
      this.dialogVisibleMap = false
    },
    //多选
    handleChange (val) {
      let arr = []
      val.forEach(item => {
        arr.push({
          parkId: item.parkId
        })
      })
      this.multiSelectList = arr
    },
    //区域管理
    handleClickLineItem (row) {
      this.$router.push({ path: '/onroadRoadParkSpaceArea', query: { parkId: row.parkId } })
    },
    //修改
    handleClickUpdate (row) {
      this.instreForm = this.$deepClone(row)
      this.handleChangeAreaCode(this.instreForm.areaCode)
      this.instreForm.areaRoad = [this.instreForm.areaCode, Number(this.instreForm.roadCode)]
      this.dialogTitle = '修改停车路段'
      this.redact = true
      this.dialogVisible = true
      this.dialogVisibleMap = false
    },
    //删除
    handleClickDelete (row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [{ parkId: row.parkId }]
        this.$yardManagementApi.deleteParkControllerBatch(info).then(() => {
          this.$systemRoleManagement.delRoleAuthority(info).then(() => {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          })
        })
      })
    },
    popClose () {
      this.instreForm = {}
      this.$refs.instreForm.resetFields();
    },
    // 批量导入
    handleInsert () { },
    //批量删除
    massDeletion () {
      if (this.multiSelectList.length === 0) {
        this.$message({
          showClose: true,
          message: '至少勾选一条记录',
          type: 'error'
        });
      } else {
        this.$confirm('确认批量删除路段泊位信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$yardManagementApi.deleteParkControllerBatch(this.multiSelectList).then(response => {
            if (response.resultCode === '2000') {
              this.$message({ message: '批量删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },
    //获取停车场
    getAllparkingList () {
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": 1 //这个是停车场类型 1是路内2是路外
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.allParkingList = res.resultEntity
      })
    },
    //获取停车场类型
    getQueryDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'DDB0648A7F1A43969486C5ECD36E650D',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.parkTypeList = response.resultEntity
      })
    },
    //获取归属企业
    getQueryDictCom () {
      let info = {
        columnName: ['company_id', 'company_name'],
        tableName: 'tb_company',
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.enterpriseList = response.resultEntity
      })
    },
    //获取计费规则
    getAccountingRules () {
      let info = {
        columnName: ['billing_rule_def_id', 'billing_rule_def_name'],
        tableName: 'tb_billing_rule_def',
        whereStr: [
          {
            colName: 'delete_flag',
            value: '1',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.billingRulesList = response.resultEntity
      })
    },
    // 获取行政区
    getRegion () {
      let info = {
        "columnName": ["region_code", "region_name"],
        "tableName": "tb_region"
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.regionCodeList = res.resultEntity
      })
    },
    // 行政区域发生改变
    handleChangeAreaCode (val) {
      console.log(val);
      let info = {
        columnName: ['road_code', 'road_name'],
        tableName: 'tb_road',
        whereStr: [
          {
            colName: 'region_code',
            value: val,
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.roadList = response.resultEntity
      })
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      line-height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.mapContent {
  position: relative;
  .map-container {
    width: 450px;
    height: 610px;
    position: absolute;
    margin-top: -85%;
    right: -268%;
    #map {
      width: 100%;
      height: 100%;
      z-index: 9999;
    }
  }
}
</style>
